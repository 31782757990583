import firebase from "firebase/app";
import "firebase/auth";

import parseError from "./parseError";

export default async (verificationId, verificationCode, resolver) => {
    var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    return await resolver.resolveSignIn(multiFactorAssertion).catch(error => {
        return parseError(error);
    });
};
