import gql from "graphql-tag";

// CLIENT-SIDE SCHEMA
const typeDefs = gql`
    directive @live on OBJECT | FIELD_DEFINITION
    scalar FlexObject

    extend type Mutation {
        addMessage(text: String, type: String): String
        removeMessage(id: String): String
        show(view: String, url: String): String
        monitor: String
        route(url: String): String
        goBack: String
        showOverlay(overlay: String, clientId: ID, ticketId: ID): String
        saver(value: String): String
    }

    extend type Query {
        myId: String
        myUID: String
        view: String
        token: String
        viewData: FlexObject
        messages: [Message]!
        routeHistory: [String]!
        loggedIn: Boolean
        overlay: String
        overlayDetails: OverlayDetails
        saver: Boolean
        currentRoute: String
    }

    type OverlayDetails {
        ticketId: ID
        clientId: ID
    }

    # User parts
    type Message {
        id: String
        text: String
        type: String
    }
`;

export default typeDefs;
