import firebase from "firebase/app";
import "firebase/auth";

export default async () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
        login_hint: "Must be @hioperator.com email",
    });

    firebase.auth().signInWithPopup(provider);
};
