import firebase from "firebase/app";
import "firebase/auth";

export default async client => {
    firebase
        .auth()
        .signOut()
        .then(
            function() {
                client.resetStore();
                window.location.href = "/";
                // Sign-out successful.
            },
            function(error) {
                // An error happened.
            }
        );
    return;
};
