import React from "react";
import { ApolloProvider, useQuery } from "@apollo/client";

import graphql from "../graphql/index";
import { Loading } from "ui-core";

// Layers
import MessagesLayer from "../components/_layers/Messages.react";
import OverlaysLayer from "../components/_layers/Overlays.react";

// Queries
import userQuery from "../queries/me";
import localQuery from "../queries/local";

const client = graphql();

var load = (props) => {
    // Splits URL
    const myRoute = document.location.hash.replace("#", "").toLowerCase();
    var myRouteArray = myRoute.split("/");
    if (myRouteArray.length === 1 && myRouteArray[0] === "") myRouteArray = [];

    var target = "components";
    if (props.localData.loggedIn !== true && myRouteArray.indexOf("login") === -1) {
        target = "components/login";
    } else if (myRouteArray.length === 0) target += "/discover";
    else {
        myRouteArray.forEach((item) => {
            if (item.indexOf("_") === 0) target += "/:id";
            else target += "/" + item;
        });
    }

    console.log("The target is " + target);
    const TargetComponent = React.lazy(() => import("../" + target));

    return (
        <ApolloProvider client={client}>
            <Page id="base-page" key="full-page" {...props} component={TargetComponent} />
        </ApolloProvider>
    );
};

// Global page component that mounts the target page
const Page = (props) => {
    const loggedIn = props.localData.loggedIn;
    const TargetComponent = props.component;

    const { loading, data, refetch } = useQuery(loggedIn ? userQuery : localQuery, {
        variables: loggedIn ? { uid: props.localData.myUID } : {},
        pollInterval: 10000,
    });

    console.log("LOADING PAGE!!!");

    if (loading && [null, undefined].indexOf(data) > -1) return <Loading />;
    if (loggedIn && !data.loggedIn) {
        return <Loading />;
    }
    const { user, ...localData } = data;

    console.log(user);
    console.log("LOADING AUTHENTICATED PAGE!!!");
    return [
        <TargetComponent id="base" key="base" user={loggedIn ? user : null} userRefetch={refetch} localData={localData} />,
        <MessagesLayer id="messages" key="messages" localData={localData} />,
        <OverlaysLayer id="overlays" key="overlays" />,
    ];
};

export { load };
