import firebase from "firebase/app";
import "firebase/auth";

import parseError from "./parseError";

export default async data => {
    var resolver;
    return await firebase
        .auth()
        .signInWithEmailAndPassword(data.email.toLowerCase(), data.password)
        .then(() => true)
        .catch(error => {
            console.log("HIT ERROR ON AUTHENTICATION!!!!");
            console.log(error);

            if (error.code === "auth/multi-factor-auth-required") {
                resolver = error.resolver;
                // Ask user which second factor to use.
                var selectedIndex = 0;

                if (resolver.hints[selectedIndex].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
                    var phoneInfoOptions = {
                        multiFactorHint: resolver.hints[selectedIndex],
                        session: resolver.session
                    };

                    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                    var appVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", { size: "invisible" });

                    // Send SMS verification code
                    //phoneInfoOptions
                    return phoneAuthProvider
                        .verifyPhoneNumber(phoneInfoOptions, appVerifier)
                        .then(function(verificationId) {
                            console.log("CODE SENT!");
                            console.log(resolver);
                            console.log(verificationId);
                            //return { twofactor: "Boba" };
                            return { twofactor: { resolver, verificationId } };
                        })
                        .catch(error => {
                            console.log("SENDING OUT PHONE AUTH ERROR!");
                            console.log(error);
                            return parseError(error);
                        });
                } else {
                    console.log("UNSUPPORTED SECOND FACTOR");
                    // Unsupported second factor.
                }
            } else {
                // Handle other errors such as wrong password.
                return parseError(error);
            }
        });
};
